<template>
  <div
    class="checkout-header hidden md:block"
    :style="{
      backgroundImage: 'url(' + `${checkoutHeaderImage}` + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
    }"
  >
    <div class="header-content">
      <div class="back-button" @click="backClickHandler">
        <div class="icon">
          <i class="pi pi-arrow-left" />
        </div>
      </div>
      <p class="header">kassen</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLocalePath } from "#i18n";
import checkoutHeaderImage from "~/assets/logo/checkoutHeader.svg";

const localePath = useLocalePath();
const backClickHandler = async () => {
  await navigateTo({ path: localePath("/menu") });
};
</script>
